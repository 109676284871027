export function camelCaseKeys(obj) {
  if (!obj) return null;
  if (typeof obj !== "object") return obj;

  if (Array.isArray(obj)) {
    return obj.map((item) => {
      return camelCaseKeys(item);
    });
  }

  return Object.entries(obj).reduce((acc, [key, val]) => {
    const modifiedKey = key.replace(/_([a-z])/g, (g) => g[1].toUpperCase());
    const modifiedVal = typeof val === "object" ? camelCaseKeys(val) : val;
    return {
      ...acc,
      ...{ [modifiedKey]: modifiedVal }
    };
  }, {});
}

export function unCamelCaseKeys(obj) {
  if (!obj) return null;
  if (typeof obj !== "object") return obj;

  if (Array.isArray(obj)) {
    return obj.map((item) => {
      return unCamelCaseKeys(item);
    });
  }

  return Object.entries(obj).reduce((acc, [key, val]) => {
    const modifiedKey = key.replace(/([A-Z])/g, "_$1").toLowerCase();
    const modifiedVal = typeof val === "object" ? unCamelCaseKeys(val) : val;
    return {
      ...acc,
      ...{ [modifiedKey]: modifiedVal }
    };
  }, {});
}

export function unCamelCaseQueryString(endpoint) {
  const queryString = endpoint.split("?")[1];
  if (!queryString) return endpoint;

  const newQueryString = queryString
    .replace(/([a-z])([A-Z])/g, "$1_$2")
    .replace(/[a-z]_([A-Z])/g, function (match) {
      return match.toLowerCase();
    });
  const path = endpoint.replace(`?${queryString}`, "");
  return `${path}?${newQueryString}`;
}
