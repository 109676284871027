import "../styles/global.css";
import "../styles/animations.scss";

import Notifications from "components/notifications";
import ServiceWorkerHandler from "components/service_worker_handler";

import { NotificationsProvider } from "hooks/notifications";
import { AuthProvider } from "hooks/user";

import { QueryClientProvider, QueryClient } from "react-query";

import { useMounted } from "hooks/react";

const queryClient = new QueryClient();

export default function App({ Component, pageProps, err }): JSX.Element {
  useMounted(() => {
    localStorage.setItem("last_full_render_at", String(new Date()));
  });

  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider user={null}>
        <NotificationsProvider>
          <ServiceWorkerHandler />

          <Component {...pageProps} err={err} />

          <Notifications />
        </NotificationsProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
}
