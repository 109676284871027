import Router from "next/router";
import React from "react";

import { persistedUser } from "utils/auth";

import { useState, useEffect, createContext, useContext } from "react";

const AuthContext = createContext(null);

export function AuthProvider({ user: givenUser, children }) {
  const [user, setUser] = useState(givenUser);

  return (
    <AuthContext.Provider value={{ user, setUser }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useUser(
  redirectTo = "",
  redirectIfFound = false,
  skipUser = false
) {
  const { user, setUser } = useContext(AuthContext);

  useEffect(() => {
    if (skipUser) return;

    if (persistedUser()) setUser(persistedUser());

    if (!redirectTo || persistedUser() === undefined) return;

    if (
      (persistedUser() && redirectIfFound) ||
      (!persistedUser() && !redirectIfFound)
    ) {
      Router.replace(redirectTo);
    }
  }, [skipUser]);

  function setAndPersistUser(newUser) {
    setUser(newUser);
    localStorage.setItem("current_user", JSON.stringify(newUser));
  }

  return { user, setUser: setAndPersistUser };
}
