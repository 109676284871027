import { filterObjByPresent, filterObjByKeys } from "utils";
import { unCamelCaseKeys } from "./styling";

import { Filters } from "types/filters";
import { Topic } from "types/topic";

const normalizedFilterKeys = [
  "institution",
  "year",
  "language",
  "subject",
  "topic"
];

export function apiNormalizeFilters(filters: Filters): Filters {
  let attributes = filterObjByPresent(filters);
  attributes = { ...attributes, topics: apiNormalizeTopics(filters) };
  if (attributes.topics.length > 0) delete attributes.language;
  delete attributes.subject;
  delete attributes.topic;
  return attributes;
}

export function normalizeFilters(filters: Filters): Filters {
  if (!filters) return {};

  const attributes: any = filterObjByKeys(filters, normalizedFilterKeys);
  if (filters.subject) delete attributes.language;
  return unCamelCaseKeys(
    filterObjByPresent({
      ...attributes,
      subject:
        filters.subject?.name ||
        filters.subject ||
        parentTopic(filters.topics)?.name,
      topic:
        filters.topic?.name || filters.topic || subTopic(filters.topics)?.name
    })
  );
}

export function filtersText(filters: Filters): string {
  const normalizedFilters = normalizeFilters(filters);
  const { institution, year, subject, topic } = normalizedFilters;
  const filtersList = [institution, year, subject, topic].filter(
    (item) => !!item
  );

  if (filtersList.length > 0) {
    return filtersList.join(" - ");
  } else {
    return "Questões aleatórias";
  }
}

export function parentTopic(topics: Topic[]): Topic {
  if (!topics) return null;

  return topics.find((topic) => {
    return !topic.parentId;
  });
}

export function subTopic(topics: Topic[]): Topic {
  if (!topics) return null;

  const parent = parentTopic(topics);
  if (!parent) return null;

  return parent.children
    ? parent.children[0]
    : topics.find((topic) => topic.parentId);
}

function apiNormalizeTopics(filters) {
  const { subject, topic } = filters;
  if (!subject) return [];

  return [subject?.slug, topic?.slug].filter((slug) => !!slug);
}
