import * as legacySessionsApi from "api/legacy_sessions";
import * as legacyQuestionnairesApi from "api/legacy_questionnaires";
import * as legacyQuestionnaireOwnershipsApi from "api/legacy_questionnaire_ownerships";
import * as legacyAnonTokens from "api/legacy_anon_tokens";

import { isServer } from "utils/next";
import * as tracker from "utils/tracker";
import { track, identify, setProfile, trackConversion } from "utils/tracker";

export async function handleSignUp({
  user,
  token,
  attributes,
  method
}): Promise<void> {
  trackSignUp(user, method);
  identify(user.id);

  return persistAuth(token, attributes, true).then((userLegacyToken) => {
    return transferQuestionnaires(userLegacyToken).then(() => {
      localStorage.setItem("jwt", userLegacyToken);
    });
  });
}

export function logout(): void {
  localStorage.clear();
  tracker.reset();
}

export function persistAuth(token, params, skipLegacyTokenPersistence = false) {
  localStorage.setItem("auth_token", token);
  return legacySessionsApi.create(params).then((response) => {
    if (skipLegacyTokenPersistence) return response.body.jwt;

    localStorage.setItem("jwt", response.body.jwt);
  });
}

export function persistedUser() {
  if (isServer()) return;

  const persistedData = localStorage.getItem("current_user");
  return persistedData && JSON.parse(persistedData);
}

function transferQuestionnaires(newLegacyToken) {
  return legacyAnonTokens.update(newLegacyToken).then(() => {
    return legacyQuestionnairesApi.index().then((response) => {
      const questionnaire = response.body[0];
      if (!questionnaire) return;

      return legacyQuestionnaireOwnershipsApi.update(questionnaire.id);
    });
  });
}

function trackSignUp(user, method) {
  track("User signed up", { method });
  trackConversion("sign_up");
  setProfile(user);
}
