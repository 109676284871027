import { nanoid } from "nanoid";

import {
  useState,
  useEffect,
  useRef,
  createContext,
  useContext,
  useCallback
} from "react";

const NotificationsContext = createContext(null);

interface NotificationsProviderProps {
  children: React.ReactNode;
}

export function NotificationsProvider(
  props: NotificationsProviderProps
): JSX.Element {
  const { children } = props;

  const [notifications, setNotifications] = useState([]);

  return (
    <NotificationsContext.Provider value={[notifications, setNotifications]}>
      {children}
    </NotificationsContext.Provider>
  );
}

export function useNotifications() {
  const [notifications, setNotifications] = useContext(NotificationsContext);
  const [newNotification, setNotification] = useState(null);

  const notificationsRef = useRef([]);
  notificationsRef.current = notifications;

  const handleDestroy = useCallback(
    (notificationId) => {
      const newNotifications = notificationsRef.current.filter(
        (notification) => {
          return notification.id !== notificationId;
        }
      );
      setNotifications(newNotifications);
    },
    [setNotifications]
  );

  useEffect(() => {
    if (!newNotification) return;

    const existentNotification = notificationsRef.current.find(
      (notification) => {
        return (
          newNotification.title === notification.title &&
          newNotification.text === notification.text
        );
      }
    );

    function addNewNotification() {
      const id = nanoid();
      setNotifications([
        ...notificationsRef.current,
        Object.assign(newNotification, {
          id,
          onDestroy: () => handleDestroy(id)
        })
      ]);
    }

    function shakeExistingNotification() {
      const newNotifications = notificationsRef.current.map((notification) => {
        if (
          JSON.stringify(notification) === JSON.stringify(existentNotification)
        ) {
          return { ...notification, shake: [true] };
        } else {
          return notification;
        }
      });
      setNotifications(newNotifications);
    }

    if (!existentNotification) return addNewNotification();

    shakeExistingNotification();
  }, [newNotification, handleDestroy, setNotifications]);

  return { notifications, setNotification } as const;
}
