import mixpanel from "mixpanel-browser";

import { captureMessage } from "@sentry/nextjs";
import { waitUntilLoaded } from "utils/browser";
import { unCamelCaseKeys } from "utils/styling";

const CONVERSION_IDS = {
  sign_up: process.env.NEXT_PUBLIC_GOOGLE_ADS_SIGNUP_CONVERSION_ID,
  subscription: process.env.NEXT_PUBLIC_GOOGLE_ADS_SUBSCRIPTION_CONVERSION_ID
};
const googleAdsId = process.env.NEXT_PUBLIC_GOOGLE_ADS_ID;

declare global {
  interface Window {
    gtag: any;
  }
}

export function track(event: string, properties = {}): void {
  properties = unCamelCaseKeys(properties);
  if (process.env.NODE_ENV !== "production")
    console.log(`[Tracking] ${event}`, properties);

  if (!process.env.NEXT_PUBLIC_MIXPANEL_TOKEN) return;

  init();
  mixpanel.track(event, properties);

  const eventName = event.toLowerCase().split(" ").join("_");
  waitUntilLoaded("gtag", () => {
    window.gtag("event", eventName, {
      application: true,
      ...properties
    });
  });
}

export function increment(action: string, count = 1): void {
  if (process.env.NODE_ENV !== "production")
    console.log(`[Incrementing] ${action}`);

  if (!process.env.NEXT_PUBLIC_MIXPANEL_TOKEN) return;

  init();
  mixpanel.people.increment(action, count);
}

export function identify(id: number): void {
  if (!process.env.NEXT_PUBLIC_MIXPANEL_TOKEN) return;

  init();
  mixpanel.identify(id);
}

export function setProfile(user: { name: string; email: string }): void {
  if (!process.env.NEXT_PUBLIC_MIXPANEL_TOKEN) return;

  init();
  mixpanel.people.set({ $email: user.email, $name: user.name });
}

export function setPeople(properties: any): void {
  if (!process.env.NEXT_PUBLIC_MIXPANEL_TOKEN) return;

  init();
  mixpanel.people.set(unCamelCaseKeys(properties));
}

export function reset(): void {
  if (!process.env.NEXT_PUBLIC_MIXPANEL_TOKEN) return;

  init();
  mixpanel.reset();
}

export function trackConversion(event) {
  const conversionId = CONVERSION_IDS[event];

  if (!conversionId) {
    return captureMessage("Tried to report invalid conversion", {
      extra: { conversion: event }
    });
  }

  waitUntilLoaded("gtag", () => {
    window.gtag("event", "conversion", {
      send_to: `${googleAdsId}/${conversionId}`
    });
  });
}

function init() {
  mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN);
}
