import {
  camelCaseKeys,
  unCamelCaseKeys,
  unCamelCaseQueryString
} from "utils/styling";
import { isServer, isBrowser } from "utils/next";

import * as Sentry from "@sentry/browser";

import { baseUrl as legacyApiUrl } from "./legacy_api";

export interface RequestResponse {
  body: any;
  statusCode: number;
  status: string;
}

declare global {
  interface Window {
    anon_login: Promise<void>;
  }
}

export function request(
  baseUrl: string,
  endpoint: string,
  opts = {}
): Promise<RequestResponse> {
  const hasAbortController = typeof AbortController !== "undefined";
  const controller = hasAbortController ? new AbortController() : null;

  if (authToken()) {
    return addAbortToPromise(
      executeRequest(baseUrl, endpoint, opts, controller),
      controller
    );
  }

  const promise = getAuthToken(baseUrl, controller).then(() => {
    return executeRequest(baseUrl, endpoint, opts, controller);
  });

  return addAbortToPromise(promise, controller);
}

async function executeRequest(
  baseUrl,
  endpoint,
  opts = {},
  abortController = null
) {
  opts = appendOpts(opts);
  if (isBrowser()) opts = appendAuth(opts, baseUrl);
  endpoint = unCamelCaseQueryString(endpoint);
  endpoint = appendRequestNumber(endpoint);

  if (abortController) {
    const signal = abortController.signal;
    // @ts-expect-error Add signal to cancel request
    opts.signal = signal;
  }

  return fetch(baseUrl + endpoint, opts).then((response) => {
    return handleResponse(response, endpoint, opts);
  });
}

function getAuthToken(baseUrl, abortController = null) {
  if (isServer() || authToken(baseUrl))
    return new Promise((resolve) => resolve(null));

  if (window.anon_login) return window.anon_login;

  const opts = { method: "POST" };
  if (abortController) {
    const signal = abortController.signal;
    // @ts-expect-error Add signal to cancel request
    opts.signal = signal;
  }

  window.anon_login =
    window.anon_login ||
    executeRequest(legacyApiUrl, "/anonymous_tokens", opts).then((response) => {
      localStorage.setItem("jwt", response.body.jwt);
      window.anon_login = null;
    });
  return window.anon_login;
}

async function handleResponse(response, endpoint, opts) {
  const body = await response
    .json()
    .then((json) => camelCaseKeys(json))
    .catch(() => null);

  setSentryRequestMetadata(endpoint, opts);
  setSentryResponseMetadata(body, response.status);

  if (response.status >= 400) {
    throw new ApiError(response, endpoint, body);
  }

  return {
    body: body,
    statusCode: response.status,
    status: statusFromCode(response.status)
  };
}

function ApiError(response, endpoint, errorBody) {
  this.name = "ApiError";
  this.message = errorMessage(response, endpoint);
  this.statusCode = response.status;
  this.status = statusFromCode(response.status);
  this.responseBody = errorBody;
}
ApiError.prototype = Error.prototype;

function errorMessage(response, endpoint) {
  const errors = {
    401: `[Unauthorized] ${endpoint}`,
    403: `[Forbidden] ${endpoint}`,
    404: `[Not found] ${endpoint}`,
    422: `[Unprocessable entity] ${endpoint}`,
    500: `[Interval Server error] ${endpoint}`
  };
  return errors[response.status];
}

function statusFromCode(code) {
  if (code >= 500) {
    return "server_error";
  } else if (code >= 400) {
    return "client_error";
  } else if (code >= 300) {
    return "redirect";
  } else {
    return "ok";
  }
}

function appendOpts(opts) {
  if (isServer()) opts.headers = { "X-SERVER-TOKEN": process.env.SERVER_TOKEN };
  if (!opts.body) return opts;

  opts.body = JSON.stringify(unCamelCaseKeys(opts.body));
  opts.headers = {
    ...opts.headers,
    Accept: "application/json",
    "Content-Type": "application/json"
  };
  return opts;
}

function appendAuth(opts, baseUrl) {
  if (!authToken(baseUrl)) return opts;

  opts.headers = opts.headers || {};
  opts.headers["Authorization"] = `Bearer ${authToken(baseUrl)}`;
  return opts;
}

function setSentryRequestMetadata(endpoint, opts) {
  Sentry.setContext("request", {
    endpoint,
    ...opts
  });
}

function setSentryResponseMetadata(body, status) {
  Sentry.setContext("response", { body: JSON.stringify(body), status });
}

function authToken(baseUrl = null) {
  if (isServer()) return null;

  if (baseUrl === legacyApiUrl) return localStorage.getItem("jwt");

  return localStorage.getItem("auth_token") || localStorage.getItem("jwt");
}

function appendRequestNumber(endpoint) {
  if (!isBrowser() || window.location.host.indexOf("localhost") === -1)
    return endpoint;

  const nextNumber = nextRequestNumber(endpoint);
  if (endpoint.indexOf("?") === -1)
    return endpoint + `?request_number=${nextNumber}`;

  return endpoint + `&request_number=${nextNumber}`;
}

function nextRequestNumber(endpoint) {
  const persistedNumbers = localStorage.getItem("request_numbers");
  const requestNumbers =
    (persistedNumbers && JSON.parse(persistedNumbers)) || {};
  requestNumbers[endpoint] = requestNumbers[endpoint]
    ? requestNumbers[endpoint] + 1
    : 1;
  localStorage.setItem("request_numbers", JSON.stringify(requestNumbers));
  return requestNumbers[endpoint];
}

function addAbortToPromise(promise, controller) {
  if (!controller) return promise;

  promise.cancel = () => {
    controller.abort();
  };
  return promise;
}
