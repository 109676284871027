import styles from "./loader.module.scss";

export default function Loader(props) {
  const { className } = props;

  return (
    <div className={styles.ellipsis + " " + className || ""}>
      <div />
      <div />
      <div />
      <div />
    </div>
  );
}
