import { useEffect, useState } from "react";

export function useMounted(callback = null, onDestroy = null): boolean {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
    callback && callback();
    return onDestroy;
  }, []);

  return mounted;
}
