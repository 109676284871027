export function filterObjByPresent(
  object: Record<string, any>
): Record<string, any> {
  const keys = Object.keys(object);
  keys.forEach((key) => {
    if (!object[key]) delete object[key];
  });

  return object;
}

export function filterObjByKeys(
  originalObject: Record<string, any>,
  allowedKeys: string[]
): Record<string, any> {
  return Object.keys(originalObject)
    .filter((key) => allowedKeys.includes(key))
    .reduce((obj, key) => {
      obj[key] = originalObject[key];
      return obj;
    }, {});
}

export function rejectObjByKeys(
  originalObject: Record<string, any>,
  rejectedKeys: string[]
): Record<string, any> {
  return Object.keys(originalObject)
    .filter((key) => !rejectedKeys.includes(key))
    .reduce((obj, key) => {
      obj[key] = originalObject[key];
      return obj;
    }, {});
}

export function objectToQueryString(obj: Record<string, any>): string {
  let string = "";
  Object.keys(obj).forEach((key) => {
    const value = obj[key];
    if (Array.isArray(value)) {
      value.forEach((_value, i) => {
        string += string ? "&" : "";
        string += `${key}[]=${obj[key][i]}`;
      });
    } else {
      string += string ? "&" : "";
      string += `${key}=${obj[key]}`;
    }
  });
  return string;
}

export function uniqueObjs(
  array: Record<string, any>[]
): Record<string, any>[] {
  const uniqueArray = [];
  array.forEach((newElem) => {
    const alreadyAdded = uniqueArray.find(
      (elem) => JSON.stringify(elem) === JSON.stringify(newElem)
    );
    if (alreadyAdded) return;

    uniqueArray.push(newElem);
  });
  return uniqueArray;
}
