import { request } from "utils/legacy_api";
import { handleParams } from "api/questionnaires";

export function index() {
  return request("/questionnaires", { method: "GET" });
}

export function get(id: number, page = 1) {
  return request(`/questionnaires/${id}?page=${page}`, { method: "GET" });
}

export function update(id, params) {
  const attributes = handleParams(params);
  return request(`/questionnaires/${id}`, {
    method: "PATCH",
    body: attributes
  });
}
