import { request } from "utils/api";
import { filterObjByKeys } from "utils";
import { apiNormalizeFilters } from "utils/metadata";
import { objectToQueryString } from "utils";

const filterKeys = [
  "institution",
  "year",
  "language",
  "topics",
  "testDay",
  "seriousFrom",
  "questionCount",
  "page"
];

export function build(params) {
  const attributes = objectToQueryString(handleParams(params));
  return request(`/questionnaires/new?${attributes}`, { method: "GET" });
}

export function create(params) {
  const attributes = handleParams(params);
  return request("/questionnaires", { method: "POST", body: attributes });
}

export function show(questionnaireId) {
  return request(`/questionnaires/${questionnaireId}`, { method: "GET" });
}

export function handleParams(params) {
  let attributes: any = filterObjByKeys(params, [
    ...filterKeys,
    "answers",
    "notAnswered"
  ]);
  attributes = apiNormalizeFilters(params);
  if (params.examNumber) attributes.page = params.examNumber;
  if (params.seriousFrom)
    attributes.seriousFrom = params.seriousFrom.toISOString();
  return attributes;
}
