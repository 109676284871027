import { request as baseRequest } from "utils/base_api";

const localAddress = process.env.NEXT_PUBLIC_LOCAL_IP || "localhost";
const host =
  process.env.NEXT_PUBLIC_LEGACY_API_URL || `http://${localAddress}:3001`;
export const baseUrl = `${host}/api`;

export function request(endpoint, opts = {}) {
  return baseRequest(baseUrl, endpoint, opts);
}
