import Loader from "components/loader";
import { FiX } from "react-icons/fi";
import { CSSTransition } from "react-transition-group";

import clsx from "clsx";

import { useMounted } from "hooks/react";
import { useNotifications } from "hooks/notifications";
import { useState, useRef, useEffect, useCallback } from "react";

export default function Notifications(): JSX.Element {
  const { notifications } = useNotifications();

  const notificationUnits = notifications.map((notification) => {
    return <Notification key={notification.id} {...notification} />;
  });

  return (
    <div className="notifications fixed z-50 bottom-0 left-0 mb-5 px-5 w-full md:left-60 md:w-96">
      {notificationUnits}
    </div>
  );
}

function Notification(props) {
  const { title, text, type, actionText } = props;
  const { skipTimeout, callback, shake: shakeProp } = props;
  const { onDestroy } = props;

  const [loading, setLoading] = useState(false);
  const [hide, setHide] = useState(false);
  const destroySchedule = useRef(null);
  const shakeSchedule = useRef(null);

  const [shake, setShake] = useState(false);

  const handleDestroy = useCallback(() => {
    setHide(true);
    setTimeout(() => onDestroy(), 500);
  }, [onDestroy]);

  useMounted(
    () => {
      if (skipTimeout) return;

      destroySchedule.current = setTimeout(handleDestroy, 5000);
    },
    () => {
      clearTimeout(destroySchedule.current);
      clearTimeout(shakeSchedule.current);
    }
  );

  useEffect(() => {
    if (!shakeProp) return;

    setShake(true);
    clearTimeout(destroySchedule.current);
    destroySchedule.current = setTimeout(handleDestroy, 5000);
    shakeSchedule.current = setTimeout(() => setShake(false), 500);
  }, [shakeProp, handleDestroy]);

  function handleClick() {
    setLoading(true);
    callback();
  }

  function topBorder() {
    const colors = {
      warning: "border-t-primary-400",
      error: "border-t-error",
      success: "border-t-success"
    };
    return `border-t-2 ${colors[type] || "border-t-primary-400"}`;
  }

  return (
    <CSSTransition
      in={!hide}
      appear={true}
      timeout={400}
      classNames="show-left"
    >
      <div
        className={clsx(
          "notification z-[9999] relative first:mt-0 mt-3 p-3 w-full bg-white border border-gray-100 rounded-b-lg shadow-lg box-border",
          topBorder(),
          { "animate-bounce-fast": shake }
        )}
        style={{ zIndex: 9999 }}
      >
        <span className="block mb-1 font-semibold">{title}</span>
        <span dangerouslySetInnerHTML={{ __html: text }} />
        {actionText && (
          <button
            className="btn btn-text mb-0 mt-2 w-full"
            onClick={handleClick}
          >
            {loading ? <Loader /> : actionText}
          </button>
        )}
        <div
          onClick={handleDestroy}
          className="close-trigger absolute right-3 top-3 text-emphasis-low cursor-pointer"
        >
          <FiX size={16} />
        </div>
      </div>
    </CSSTransition>
  );
}
