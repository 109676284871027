import dayjs from "dayjs";

import { useMounted } from "hooks/react";
import { useNotifications } from "hooks/notifications";

export default function ServiceWorkerHandler(): JSX.Element {
  useMounted(handleMount);

  const { setNotification } = useNotifications();

  function handleMount() {
    handleServiceWorkerUpdate();
    notifyServiceWorkerUpdate();
    registerServiceWorker();
  }

  function registerServiceWorker() {
    if (
      typeof window !== "undefined" &&
      "serviceWorker" in navigator &&
      window.workbox !== undefined
    ) {
      window.workbox.register();
    }
  }

  function handleServiceWorkerUpdate() {
    if (
      typeof window !== "undefined" &&
      "serviceWorker" in navigator &&
      window.workbox !== undefined
    ) {
      const wb = window.workbox;

      const promptNewVersionAvailable = () => {
        // `event.wasWaitingBeforeRegister` will be false if this is the first time the updated service worker is waiting.
        // When `event.wasWaitingBeforeRegister` is true, a previously updated service worker is still waiting.
        // You may want to customize the UI prompt accordingly.
        const lastFullRenderAt = localStorage.getItem("last_full_render_at");
        if (lastFullRenderAt) {
          const secondsSinceLastRender = dayjs(new Date()).diff(
            lastFullRenderAt,
            "seconds"
          );
          if (secondsSinceLastRender < 60) return wb.messageSkipWaiting();
        }

        setNotification({
          type: "success",
          title: "Atualização disponível",
          text: "Fizemos alguns ajustes na plataforma :)",
          actionText: "Atualizar",
          skipTimeout: true,
          callback: () => {
            localStorage.setItem("notify_update", "true");

            wb.messageSkipWaiting();

            setTimeout(() => window.location.reload(), 2000);
          }
        });
      };

      wb.addEventListener("waiting", promptNewVersionAvailable);
    }
  }

  function notifyServiceWorkerUpdate() {
    if (!localStorage.getItem("notify_update")) return;

    localStorage.removeItem("notify_update");
    setNotification({
      title: "Aplicativo atualizado",
      text: "Tudo certo com a atualização!",
      type: "success"
    });
  }

  return <></>;
}
